/* Needed if not during blurry background, there is a white edge border thing. */
body {
	background-color: #000;
}
.entire-page-bg {
	background-color: #000;
	color: #D4D4D4;
}



/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
	background: #000;
	border-color: rgb(112, 112, 112, 0.5);
	border-width: 1px;
	border-style: solid;
}
/* Handle */
::-webkit-scrollbar-thumb {
	background: rgb(112, 112, 112, 0.5);
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgb(112, 112, 112, 0.9);
}



::selection {
	background: #999;
}


/* TDL include shadow for nav */
.nav-bg {
	background-color: #1e1e1e;
}
.nav-text-number {
	color: #c22db8;
}
.nav-text-dot {
	color: #D4D4D4;
}
.nav-text {
	color: #bce8f5;
}



.greet {
	/* Fallback: Set a background color. */
	background-color: hotpink;
	
	background-image: linear-gradient(#2a3dec, #bce8f5, #c22db8);

	/* Set the background size and repeat properties. */
	background-size: 100%;
	background-clip: text;
	

	/* Use the text as a mask for the background. */
	/* This will show the gradient as a text color rather than element bg. */
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent; 
}
	

.title {
	color: #FFF;
}
.desc {
	color: #BBB;
}
.paragraph {
	color: #AAA;
}




.btn-bg {
	background-color: transparent;
}
.btn-bg:hover {
	background-color: #CCCCCC;
}
.btn-border {
	border-style: solid;
	border-width: 1px;
	border-color: #CCCCCC;
}
.btn-text {
	color: #CCCCCC;
}
.btn-text:hover {
	color: #252526;
}
.btn-transition {
	background-color: transparent;
	transition-property: all;
	transition-timing-function: ease-in-out;
	transition-duration: 0.2s;
	transition-delay: 0s;
}





.active-about {
	border-color: #9CDCFE;
	color: #9CDCFE;
}
.not-active-about {
	border-color: #555;
	color: #555;
}
.not-active-about:hover {
	background-color: #252526;
	color: #9CDCFE;
}





.topic {
	color: #ce9178;
}
.repo-card-img-bg {
	background-color: #303030;
}
.repo-card-080 {
	background-color: rgb(64, 64, 64, 0.8);
}
.repo-card {
	background-color: #404040;
}
@media screen and (min-width: 48em) {
	.repo-card-ns {
		background-color: rgb(64, 64, 64, 0.8);
	}
}
@media screen and (min-width: 48em) and (max-width: 64em) {
	.repo-card-m {
		background-color: rgb(64, 64, 64, 0.8);
	}
}
@media screen and (min-width: 64em) {
	.repo-card-l {
		background-color: #404040;
	}
}





.employment-symbol {
	color: #007ACC;
}
.employment-company {
	color: #9CDCFE;
}

.active-employment {
	border-color: #9CDCFE;
	color: #9CDCFE;
}
.not-active-employment {
	border-color: #555;
	color: #555;
}
.not-active-employment:hover {
	background-color: #252526;
	color: #9CDCFE;
}

.better-list-style {
	position: relative;
}
.better-list-style::before {
	content: "⊳";
	position: absolute;
	color: #9CDCFE;
	left: 0px;
}



.logo-circle {
	color: #505050;
	fill: currentColor;
}
.logo-path {
	color: #FFF;
	fill: currentColor;
}
.logo svg:hover .logo-circle {
	color: #FFF;
}
.logo svg:hover .logo-path {
	color: #505050;
}


.footer-bg {
	background-color: #252526;
	margin-top: 100vh;
}
.footer {
	color: #666;
}
.footer:hover {
	color: #c22db8;
}