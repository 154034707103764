.top--20p {
  top: -20%;
}
.nav-transition-top {
	transition: top 0.5s;
}




.text-shadow-pop {
  transition-property: transform, text-shadow;
  transition-duration: 0.5s;
}
.text-shadow-pop-parent:hover .text-shadow-pop-number {
  text-shadow: 1px -1px #9c5b98, 2px -2px #9c5b98, 3px -3px #9c5b98, 4px -4px #9c5b98;
  transform: translateX(-4px) translateY(4px);
}

/* .text-shadow-pop-parent:hover .text-shadow-pop-number {
	text-shadow: 1px -1px hotpink, 2px -2px hotpink, 3px -3px hotpink, 4px -4px hotpink;
	transform: translateX(-4px) translateY(4px);
  } */


.text-shadow-pop-parent:hover .text-shadow-pop-dot {
  text-shadow: 1px -1px #707070, 2px -2px #707070, 3px -3px #707070, 4px -4px #707070;
  transform: translateX(-4px) translateY(4px);
}
.text-shadow-pop-parent:hover .text-shadow-pop-text {
  text-shadow: 1px -1px #569cd6, 2px -2px #569cd6, 3px -3px #569cd6, 4px -4px #569cd6;
  transform: translateX(-4px) translateY(4px);
}




.bar1, .bar2, .bar3{
	width: 35px;
	height: 5px;
	height: 4px;
	background-color: #9CDCFE;
	margin: 6px 0;

	border-radius: 5px;

	box-shadow: 0px 1px 4px 1px #569cd6;
}
/* Rotate first bar */
.show-x .bar1{
	transform: rotate(-45deg) translate(-9px, 6px);
	transform: rotate(-45deg) translate(-10px, 5px);
}
/* Fade out second bar */
.show-x .bar2{
	opacity: 0;
}
/* Rotate last bar */
.show-x .bar3{
	transform: rotate(45deg) translate(-8px, -8px);
	transform: rotate(45deg) translate(-8px, -5px);
}




/*
NOTE:
1. top, left and right works when position: absolute.
2. sidebar's position is absolute only when it is not breakpoint-l.
*/
.hide-sidebar {
	justify-content: center;
	align-items: center;
	align-content: center;
	top: 0;

	left: 100%;
}
.show-sidebar {
	width: 50%;
	justify-content: center;
	align-items: center;
	align-content: center;
	height: 100vh;
	top: 0;

	left: 50%;
	background-color: #252526;
}

.show-sidebar ~ .nav-toggle-bg {
	display: block;

	position: absolute;
	top: 0;
	right: 0;
	width: 100vw;
	height: 100vh;
	
	background-color: transparent;
}





.blur {
	overflow: hidden;
}
.blur .page-root {
	filter: blur(3px) brightness(0.7);
}

/* @media screen and (min-width: 48em) {
	.blur-ns { overflow: hidden; }
	.blur-ns .page-root { filter: blur(3px) brightness(0.7); }
}

@media screen and (min-width: 48em) and (max-width: 64em) {
	.blur-m { overflow: hidden; }
	.blur-m .page-root { filter: blur(3px) brightness(0.7); }
}

@media screen and (min-width: 64em) {
	.blur-l { overflow: hidden; }
	.blur-l .page-root { filter: blur(3px) brightness(0.7); }
} */