@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700|Open+Sans|Ubuntu:400,700|Ubuntu+Mono|Liu+Jian+Mao+Cao|Long+Cang&display=swap);
html {
  scroll-behavior: smooth;
}

body {
  overflow: auto;
  margin: 0;
}


a {
  display: inline-block;
  text-decoration: none;
}

a>div {
  vertical-align: bottom;
}

.cursor-default {
  cursor: default;
}


/* DO NOT CHANGE THIS.
If you must, the below is used as default animationDelay in Segment.js (as of writing, used only for the Segments, logo and the burger-menu. Both of which required special delays at the time of writing).

If you want to use for transitions, try adding transition-delay for all of them (This might work).
*/
.delay-020 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.delay-025 {
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}

.delay-050 {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

.delay-075 {
  -webkit-animation-delay: 0.75s;
          animation-delay: 0.75s;
}

.delay-100 {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.delay-125 {
  -webkit-animation-delay: 1.25s;
          animation-delay: 1.25s;
}

.delay-150 {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
}


.duration-020 {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
}

.duration-025 {
  -webkit-animation-duration: 0.25s;
          animation-duration: 0.25s;
}

.duration-050 {
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
}

.duration-075 {
  -webkit-animation-duration: 0.75s;
          animation-duration: 0.75s;
}

.duration-100 {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.duration-125 {
  -webkit-animation-duration: 1.25s;
          animation-duration: 1.25s;
}

.duration-150 {
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
}

.slide-in-bottom {
  -webkit-animation-name: slide-in-bottom;
          animation-name: slide-in-bottom;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-timing-function: cubic-bezier(0.250, 0.460, 0.450, 0.940);
          animation-timing-function: cubic-bezier(0.250, 0.460, 0.450, 0.940);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

@-webkit-keyframes slide-in-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}





.animation-ease-in {
  -webkit-animation-name: animation-ease-in;
          animation-name: animation-ease-in;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

@-webkit-keyframes animation-ease-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animation-ease-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}



.animation-paused {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

.animation-running {
  -webkit-animation-play-state: running;
          animation-play-state: running;
}



/* used by footer */
.transition-ease-in {
  transition: all 0.2s;
}

.transition-ease-in-05 {
  transition: all 0.5s;
}

.transition-ease-in-10 {
  transition: all 10s;
}

.hide {
  visibility: hidden;
}


.transition-hide-partial {
  -webkit-filter: brightness(85%);
          filter: brightness(85%);
}

.transition-hide-partial:hover {
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}



.transition-underline {
  position: relative;
}

.transition-underline::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #D4D4D4;
  background-color: #DCDCAA;
  transform-origin: bottom right;
  transform: scaleX(0);
  transition: transform 0.2s ease;
}

.transition-underline:hover::before {
  transform-origin: bottom left;
  transform: scaleX(1);
}




.dim-080:hover {
  opacity: 0.8;
}

.dim-090:hover {
  opacity: 0.9;
}




.picture {
  display: inline-block;
  position: relative;
}

.picture-frame {
  z-index: 9999;
  background-color: #C586C0;

  transition: background-color 0.2s;
}

.picture-frame:hover {
  background-color: #C586C0;
}

.picture-shadow {
  z-index: -1;

  content: "";
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  border-color: aqua;
  border-style: solid;
  border-width: 3px;
  transform: translate(15px, 15px);
}

.picture-frame:hover+.picture-shadow {
  transform: translate(10px, 10px);
}


.font-ubuntu-mono {
  font-family: 'Ubuntu Mono', monospace;
  font-weight: normal;
}

.font-ubuntu-mono-bold {
  font-family: 'Ubuntu Mono', monospace;
  font-weight: bold;
}

.font-opensans {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
}

.font-opensans-bold {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
}

.font-montserrat-bold {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}






@media screen and (min-width: 48em) {
  .o-0-ns {
    opacity: 0;
  }

  .o-100-ns {
    opacity: 100;
  }

  .hide-child-ns .child-ns {
    opacity: 0;
  }

  .hide-child-ns:hover .child-ns,
  .hide-child-ns:focus .child-ns,
  .hide-child-ns:active .child-ns {
    opacity: 1;
  }
}

@media screen and (max-width: 64em) and (min-width: 48em) {
  .o-0-m {
    opacity: 0;
  }

  .o-100-m {
    opacity: 100;
  }

  .hide-child-m .child-m {
    opacity: 0;
  }

  .hide-child-m:hover .child-m,
  .hide-child-m:focus .child-m,
  .hide-child-m:active .child-m {
    opacity: 1;
  }
}

@media screen and (min-width: 64em) {
  .o-0-l {
    opacity: 0;
  }

  .o-100-l {
    opacity: 100;
  }

  .hide-child-l .child-l {
    opacity: 0;
  }

  .hide-child-l:hover .child-l,
  .hide-child-l:focus .child-l,
  .hide-child-l:active .child-l {
    opacity: 1;
  }
}

/* Hack for project repos grid to seem even, i.e. 2 by 2, 3 by 3. */
@media screen and (min-width: 64em) {
  .w-40-l-mine {
    width: 40%;
  }

  .w-50-l-mine {
    width: 50%;
  }

  .w-60-l-mine {
    width: 60%;
  }

  .w-70-l-mine {
    width: 70%;
  }

  .w-75-l-mine {
    width: 75%;
  }

  .w-third-l-mine {
    width: 33.33333%;
  }

  .w-two-thirds-l-mine {
    width: 66.66667%;
  }
}

@media screen and (min-width: 96em) {
  .w-50-xl {
    width: 50%;
  }

  .w-60-xl {
    width: 60%;
  }

  .w-70-xl {
    width: 70%;
  }
}

@media screen and (min-width: 120em) {
  .w-40-xxl {
    width: 40%;
  }

  .w-50-xxl {
    width: 50%;
  }

  .w-60-xxl {
    width: 60%;
  }
}

@media screen and (min-width: 144em) {
  .w-30-xxxl {
    width: 30%;
  }

  .w-40-xxxl {
    width: 40%;
  }

  .w-50-xxxl {
    width: 50%;
  }
}
/* Needed if not during blurry background, there is a white edge border thing. */
body {
	background-color: #000;
}
.entire-page-bg {
	background-color: #000;
	color: #D4D4D4;
}



/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
	background: #000;
	border-color: rgb(112, 112, 112, 0.5);
	border-width: 1px;
	border-style: solid;
}
/* Handle */
::-webkit-scrollbar-thumb {
	background: rgb(112, 112, 112, 0.5);
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgb(112, 112, 112, 0.9);
}



::selection {
	background: #999;
}


/* TDL include shadow for nav */
.nav-bg {
	background-color: #1e1e1e;
}
.nav-text-number {
	color: #c22db8;
}
.nav-text-dot {
	color: #D4D4D4;
}
.nav-text {
	color: #bce8f5;
}



.greet {
	/* Fallback: Set a background color. */
	background-color: hotpink;
	
	background-image: linear-gradient(#2a3dec, #bce8f5, #c22db8);

	/* Set the background size and repeat properties. */
	background-size: 100%;
	background-clip: text;
	

	/* Use the text as a mask for the background. */
	/* This will show the gradient as a text color rather than element bg. */
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent; 
}
	

.title {
	color: #FFF;
}
.desc {
	color: #BBB;
}
.paragraph {
	color: #AAA;
}




.btn-bg {
	background-color: transparent;
}
.btn-bg:hover {
	background-color: #CCCCCC;
}
.btn-border {
	border-style: solid;
	border-width: 1px;
	border-color: #CCCCCC;
}
.btn-text {
	color: #CCCCCC;
}
.btn-text:hover {
	color: #252526;
}
.btn-transition {
	background-color: transparent;
	transition-property: all;
	transition-timing-function: ease-in-out;
	transition-duration: 0.2s;
	transition-delay: 0s;
}





.active-about {
	border-color: #9CDCFE;
	color: #9CDCFE;
}
.not-active-about {
	border-color: #555;
	color: #555;
}
.not-active-about:hover {
	background-color: #252526;
	color: #9CDCFE;
}





.topic {
	color: #ce9178;
}
.repo-card-img-bg {
	background-color: #303030;
}
.repo-card-080 {
	background-color: rgb(64, 64, 64, 0.8);
}
.repo-card {
	background-color: #404040;
}
@media screen and (min-width: 48em) {
	.repo-card-ns {
		background-color: rgb(64, 64, 64, 0.8);
	}
}
@media screen and (min-width: 48em) and (max-width: 64em) {
	.repo-card-m {
		background-color: rgb(64, 64, 64, 0.8);
	}
}
@media screen and (min-width: 64em) {
	.repo-card-l {
		background-color: #404040;
	}
}





.employment-symbol {
	color: #007ACC;
}
.employment-company {
	color: #9CDCFE;
}

.active-employment {
	border-color: #9CDCFE;
	color: #9CDCFE;
}
.not-active-employment {
	border-color: #555;
	color: #555;
}
.not-active-employment:hover {
	background-color: #252526;
	color: #9CDCFE;
}

.better-list-style {
	position: relative;
}
.better-list-style::before {
	content: "⊳";
	position: absolute;
	color: #9CDCFE;
	left: 0px;
}



.logo-circle {
	color: #505050;
	fill: currentColor;
}
.logo-path {
	color: #FFF;
	fill: currentColor;
}
.logo svg:hover .logo-circle {
	color: #FFF;
}
.logo svg:hover .logo-path {
	color: #505050;
}


.footer-bg {
	background-color: #252526;
	margin-top: 100vh;
}
.footer {
	color: #666;
}
.footer:hover {
	color: #c22db8;
}
.anim-object {
	transform: translate3d(0,0,0);
}

/* For entrance */
@-webkit-keyframes blur-in-bck-center {
  0% {
    -webkit-filter: blur(3rem) brightness(50%);
            filter: blur(3rem) brightness(50%);
    transform: translateZ(-60rem);
    opacity: 0;
  }
  100% {
    -webkit-filter: none;
            filter: none;
    transform: translateZ(-40rem);
    opacity: 1;
  }
}
@keyframes blur-in-bck-center {
  0% {
    -webkit-filter: blur(3rem) brightness(50%);
            filter: blur(3rem) brightness(50%);
    transform: translateZ(-60rem);
    opacity: 0;
  }
  100% {
    -webkit-filter: none;
            filter: none;
    transform: translateZ(-40rem);
    opacity: 1;
  }
}
.blur-in-bck-center {
	-webkit-animation: blur-in-bck-center 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;
	        animation: blur-in-bck-center 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;
}


/* For exit */
@-webkit-keyframes slide-out-bck-center {
  0% {
    transform: translateZ(-40rem);
    opacity: 1;
  }
  100% {
    transform: translateZ(-200rem);
    opacity: 0;
  }
}
@keyframes slide-out-bck-center {
  0% {
    transform: translateZ(-40rem);
    opacity: 1;
  }
  100% {
    transform: translateZ(-200rem);
    opacity: 0;
  }
}
.slide-out-bck-center {
	-webkit-animation: slide-out-bck-center 0.1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 1s both;
	        animation: slide-out-bck-center 0.1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 1s both;
}
.top--20p {
  top: -20%;
}
.nav-transition-top {
	transition: top 0.5s;
}




.text-shadow-pop {
  transition-property: transform, text-shadow;
  transition-duration: 0.5s;
}
.text-shadow-pop-parent:hover .text-shadow-pop-number {
  text-shadow: 1px -1px #9c5b98, 2px -2px #9c5b98, 3px -3px #9c5b98, 4px -4px #9c5b98;
  transform: translateX(-4px) translateY(4px);
}

/* .text-shadow-pop-parent:hover .text-shadow-pop-number {
	text-shadow: 1px -1px hotpink, 2px -2px hotpink, 3px -3px hotpink, 4px -4px hotpink;
	transform: translateX(-4px) translateY(4px);
  } */


.text-shadow-pop-parent:hover .text-shadow-pop-dot {
  text-shadow: 1px -1px #707070, 2px -2px #707070, 3px -3px #707070, 4px -4px #707070;
  transform: translateX(-4px) translateY(4px);
}
.text-shadow-pop-parent:hover .text-shadow-pop-text {
  text-shadow: 1px -1px #569cd6, 2px -2px #569cd6, 3px -3px #569cd6, 4px -4px #569cd6;
  transform: translateX(-4px) translateY(4px);
}




.bar1, .bar2, .bar3{
	width: 35px;
	height: 5px;
	height: 4px;
	background-color: #9CDCFE;
	margin: 6px 0;

	border-radius: 5px;

	box-shadow: 0px 1px 4px 1px #569cd6;
}
/* Rotate first bar */
.show-x .bar1{
	transform: rotate(-45deg) translate(-9px, 6px);
	transform: rotate(-45deg) translate(-10px, 5px);
}
/* Fade out second bar */
.show-x .bar2{
	opacity: 0;
}
/* Rotate last bar */
.show-x .bar3{
	transform: rotate(45deg) translate(-8px, -8px);
	transform: rotate(45deg) translate(-8px, -5px);
}




/*
NOTE:
1. top, left and right works when position: absolute.
2. sidebar's position is absolute only when it is not breakpoint-l.
*/
.hide-sidebar {
	justify-content: center;
	align-items: center;
	align-content: center;
	top: 0;

	left: 100%;
}
.show-sidebar {
	width: 50%;
	justify-content: center;
	align-items: center;
	align-content: center;
	height: 100vh;
	top: 0;

	left: 50%;
	background-color: #252526;
}

.show-sidebar ~ .nav-toggle-bg {
	display: block;

	position: absolute;
	top: 0;
	right: 0;
	width: 100vw;
	height: 100vh;
	
	background-color: transparent;
}





.blur {
	overflow: hidden;
}
.blur .page-root {
	-webkit-filter: blur(3px) brightness(0.7);
	        filter: blur(3px) brightness(0.7);
}

/* @media screen and (min-width: 48em) {
	.blur-ns { overflow: hidden; }
	.blur-ns .page-root { filter: blur(3px) brightness(0.7); }
}

@media screen and (min-width: 48em) and (max-width: 64em) {
	.blur-m { overflow: hidden; }
	.blur-m .page-root { filter: blur(3px) brightness(0.7); }
}

@media screen and (min-width: 64em) {
	.blur-l { overflow: hidden; }
	.blur-l .page-root { filter: blur(3px) brightness(0.7); }
} */
